body {
  background-color: #1c2329
}
.App {
  text-align: center;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.char-stat-table {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  border: 1px solid #ddd;
  width: 100%;
  table-layout: auto;
}

.theme-td {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border: 1px solid #dddddd;
  text-align: center;
  padding: 8px; 
  color: #ffffff
}
tr:hover {background-color: #666666;}

.theme-th {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border: 1px solid #dddddd;
  text-align: center;
  padding: 8px;  
  background-color: #fe931f;
}

.image-txt-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 18px;
  width: 25%;
  height: 50%;
}


.character-img{
  border: 1px solid rgb(0, 0, 0);
  border-radius: 8px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 400px;
  height: 400px;
}

.character-img:hover {
  box-shadow: 0 0 2px 1px #fe931f;
}

.char-container {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.usr-char-image {
  border: 1px solid rgb(0, 0, 0);
  border-radius: 8px;
  display: box;
  float: left;
  width: 200px;
  height: 200px;
  margin-bottom: 5px;

}

.usr-char-image:hover{
  box-shadow: 0 0 2px 1px #fe931f;
}

.hp-input{
  height:50px;
  font-size:40px;
}

.spell-input{
  height:25px;
  font-size:30px;
}


.close-CSS {
  background-size: 100px 130px;
  height: 500px;  
  width: 104px;
}


p { 
  color: #adb7bd;
  font-family: 'Lucida Sans', Arial, sans-serif;
  font-size: 16px;
  line-height: 26px;
  margin: 0; 
}

.theme-p-centered{
  text-align: center;
  color: #adb7bd;
  font-family: 'Lucida Sans', Arial, sans-serif;
  font-size: 16px;
  line-height: 26px;
  margin: 0; 
}

.theme-h1 { 
  color: #ffffff;
  font-family: 'Lato', sans-serif;
  font-size: 54px; font-weight: 300;
  line-height: 58px; margin: 0 0 0px;
  text-align: center;
}

.theme-h2 {
  color: #ffffff;
  font-family: 'Lato', sans-serif;
  font-size: 40px; font-weight: 300;
  line-height: 58px; margin: 0 0 0px;
}

.theme-h3 { 
color: #ffffff;
font-family: 'Lato', sans-serif;
font-size: 30px;
font-weight: 300;
line-height: 58px;
margin: 0 0 0px;
}

.theme-h4 { 
  color: #ffffff;
  font-family: 'Lato', sans-serif;
  font-size: 25px;
  font-weight: 300;
  line-height: 58px;
  margin: 0 0 0px; 
}

.theme-h2-centered{
  color: #ffffff;
  font-family: 'Lato', sans-serif;
  font-size: 40px;
  font-weight: 300;
  line-height: 58px;
  margin: 0 0 0px;
  text-align: center;
}

.theme-h1-user-page { 
  color: #ffffff;
  font-family: 'Lato', sans-serif;
  font-size: 54px; font-weight: 300;
  line-height: 58px; margin: 0 0 0px;
  text-align: center;
}

.theme-h2-user-page { 
  display: block;
  width: 500px;
  height: 60px;
  margin-bottom: 5px;
  color: #ffffff;
  font-family: 'Lato', sans-serif;
  font-size: 40px; font-weight: 300;
  line-height: 58px; margin: 0 0 0px;
  text-align: center;
}

.theme-h2-user-dm-page { 
  display: block;
  height: 60px;
  margin-bottom: 5px;
  color: #ffffff;
  font-family: 'Lato', sans-serif;
  font-size: 40px; font-weight: 300;
  line-height: 58px; margin: 0 0 0px;
  text-align: center;
}


.form-holder {
  border-radius: 15px;
  background-color: #fff;
  overflow: hidden;
  margin-top: 200px;
  margin-left: 45%;
  opacity: 1;
  visibility: visible;
  width: 200px;


}
  .login-input {
    border: 0;
    outline: none;
    box-shadow: none;
    display: block;
    height: 30px;
    line-height: 30px;
    padding: 8px 0px;
    border-bottom: 1px solid #eee;
    width: 100%;
    font-size: 20px;
    text-align: center;

}

.button-roll{
  border: 1px solid rgb(0, 0, 0);
  border-radius: 8px;
  display: box;
  float: left;
  width: 200px;
  height: 200px;

}

.button-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-left: 45%;
  height: 300px;
}

.button-roll:hover {
  box-shadow: 0 0 2px 1px #fe931f;
}
